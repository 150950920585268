'use client'
import * as React from 'react'
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { PrismicNextImage, PrismicNextLink } from "@prismicio/next";
import NextLink from 'next/link'
import { useIntersection } from 'react-use';
import dynamic from 'next/dynamic'
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });
import _ from 'lodash'
import Arrow from '@/assets/icons/right-arrow.svg'

/**
 * Props for `VideoProducts`.
 */
export type VideoProductsProps =
  SliceComponentProps<Content.VideoProductsSlice>;

/**
 * Component for "VideoProducts" Slices.
 */
const VideoProducts = ({ slice, context }: VideoProductsProps): JSX.Element => {

  const [isMobile, setIsMobile] = React.useState(false);

  const intersectionRef = React.useRef<HTMLDivElement>(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  });


  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640); // Adjust the breakpoint for mobile size if needed
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    if (intersection && intersection.intersectionRatio >= 0.5 && intersectionRef.current) {
      window.scrollTo({
        top: intersectionRef.current.offsetTop,
        behavior: 'smooth'
      });
    }
  }, [intersection]);

  const primary = slice.primary as any;

  const fadeInOutArray = _.map(slice.items, (product) => ({
    fade_in: product.fade_in,
    fade_out: product.fade_out
  }));

  const panelRefs = React.useRef<{ [key: number]: HTMLDivElement | null }>({});

  const showPanel = (timeData: any) => {
    const played = timeData.playedSeconds;

    fadeInOutArray.forEach((times: any, index: number) => {
      if (times.fade_in <= played && played < times.fade_out) {
        panelRefs.current[index]?.classList.remove("opacity-0", "off-screen");
        panelRefs.current[index]?.classList.add("on-screen");
      } else {
        panelRefs.current[index]?.classList.add("opacity-0");
        // Use setTimeout to add the off-screen class after the opacity transition
        setTimeout(() => {
          panelRefs.current[index]?.classList.add("off-screen");
        }, 500); // Delay matching the opacity transition duration
        panelRefs.current[index]?.classList.remove("on-screen");
      }
    });
  }

  if(!isMobile) {


  return (
      <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className={"w-screen h-screen mb-16 relative "+(intersection && intersection.intersectionRatio >= 0.5 && intersectionRef.current ? "z-[109]" : "z-1")}
        ref={intersectionRef}
      >
        <div className="flex items-center justify-center h-screen ">
          {primary.image?.url && !primary.video ?
            <>
            <PrismicNextImage field={primary.image} sizes="(max-width: 419px) 380px, (max-width: 640px) 300px, 600px" className="absolute top-0 bottom-0 left-0 right-0 w-full object-cover object-top" fallbackAlt="" />
            </>
            :
            <div className="video-container relative -top-[20vh] md:top-0 ">
              <div className="video-wrapper videoPromo ">
                <ReactPlayer url={primary.video.url} loop
                  playing={intersection && intersection.intersectionRatio < 0.5 ? false : true}
                  playsinline muted width="177.78%" height="100%"
                  className="video-player"
                  onProgress={(timeData: any) => showPanel(timeData)} />
              </div>
            </div>
          }
          {slice.items.map((item: any, index: number) => {

            if (!panelRefs.current[index]) {
              // Initialize a ref at each index if it doesn't exist
              panelRefs.current[index] = React.createRef<HTMLDivElement>().current;
            }

            let setPosition = "";
            switch (item.position) {
              case 'Top Left':
                setPosition = "top-[40vh] md:left-6 md:right-auto md:top-6 lg:left-[54px] lg:top-12";
                break;
              case 'Top Right':
                setPosition = "top-[40vh] md:right-6 md:left-auto md:top-6 lg:right-[54px] lg:top-12";
                break;
              case 'Bottom Left':
                setPosition = "top-[40vh] md:top-auto md:right-auto md:left-6 md:bottom-6 lg:left-[54px] lg:bottom-12";
                break;
              case 'Bottom Right':
                setPosition = "top-[40vh] md:top-auto md:left-auto md:right-6 md:bottom-6 lg:right-[54px] lg:bottom-12";
                break;
              case 'Center Center':
                setPosition = "top-[40vh] md:top-auto";
                break;
              default:
            }
            const parent = item?.parent?.uid ? "/"+item.parent.uid : item?.parent?.url ? item.parent.url : ''
            const subsection = item?.subsection?.uid ? "/"+item.subsection.uid : ''
            const link = item?.link?.uid ? "/"+item.link.uid : ''
            const contextData = context as any
            
            return (
              <div className={"absolute max-w-[280px] max-h-[310px] w-screen h-screen mx-auto bg-white p-4 transition-js-opacity duration-500 transition-opacity off-screen " + setPosition} key={index} ref={(el: any) => (panelRefs.current[index] = el)}>
                <NextLink href={contextData.currency+"/"+parent+subsection+link} className="hover:opacity-60">
                  <PrismicNextImage field={item.image} sizes="(max-width: 419px) 380px, (max-width: 640px) 300px, 600px" className="w-full object-cover object-center max-h-[317px] aspect-square" fallbackAlt="" />
                  <h3 className="uppercase font-norma font-medium text-[10px] leading-auto inline-block relative w-full">{item.product_name} <Arrow className="rotate-180 inline-block absolute right-0 top-1 stroke-warmcharcoal" /></h3>
                </NextLink>
              </div>
            );
          })}
        </div>
      </section>
  )
  } else return <></>;
};

export default VideoProducts;
