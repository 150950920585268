'use client'
import { useState } from 'react'
import { Content } from "@prismicio/client";
import { SliceComponentProps, PrismicRichText } from "@prismicio/react";
import Chevron from '@/assets/icons/chevron.svg'

/**
 * Props for `Faqs`.
 */
export type FaqsProps = SliceComponentProps<Content.FaqsSlice>;

/**
 * Component for "Faqs" Slices.
 */


const FaqItem = ({ item }: { item: any }): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="" onClick={() => setIsOpen(!isOpen)}>
      <div className="grid grid-cols-12 border-b-[1px] border-b-warmcharcoal py-2">
        <div className="col-span-11">
          <h3 className="text-[14px] leading-[20px] lg:text-[16px] lg:leading-[22px] text-charcoal font-norma">{item.question}</h3>
        </div>
        <div className="col-span-1 flex justify-end items-center">
          <Chevron className={"stroke-warmcharcoal transition-all duration-300 relative top-2 " + (isOpen ? "rotate-0" : "rotate-180")}/>
        </div>
      </div>
      {isOpen &&
        <article className="py-8 text-[14px] leading-[20px] lg:text-[16px] lg:leading-[22px] text-charcoal font-norma px-8 lg:px-[100px] prose prose-li:ml-0 prose-li:pl-0 prose-ul:ml-0  prose-ul:pl-4 marker:text-warmcharcoal">
          <PrismicRichText field={item.description}/>
        </article>
      }
    </div>
  );
};


const Faqs = ({ slice }: FaqsProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="w-screen p-5 lg:px-[54px] max-w-[880px] mx-auto pb-[104px]">
      <div className="relative cursor-pointer">
        <h2 className="font-sangbleu text-[21px] leading-[32px] mt-[20px] pb-5 md:mb-[38px]">{slice.primary.heading}</h2>
        <div className="">
          {slice.items?.map((item: any, index: number)=>{
            return (
              <FaqItem item={item} key={index}/>
            )
          })}
        </div>
      </div>
    </section>
  )
};

export default Faqs;
