'use client'
import { Content } from "@prismicio/client";
import { SliceComponentProps, PrismicRichText } from "@prismicio/react";
import { PrismicNextLink } from '@prismicio/next'
import Arrow from '@/assets/icons/right-arrow.svg'
import { linkResolver } from '@/prismicio'

/**
 * Props for `CtaPanel`.
 */
export type CtaPanelProps = SliceComponentProps<Content.CtaPanelSlice>;

/**
 * Component for "CtaPanel" Slices.
 */
const CtaPanel = ({ slice }: CtaPanelProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={"w-screen text-center pt-[140px] pb-[120px] px-4 "+(slice.primary.theme === true ? "bg-warmcharcoal text-offwhite mb-24" : "bg-lightwarmgrey text-warmcharcoal")}
    >
      <h2 className="text-[21px] leading-[32px] font-sangbleu font-medium">{slice.primary.heading}</h2>
      <article className="font-norma text-[16px] mt-4 mb-2">
        <PrismicRichText field={slice.primary.description} />
      </article>
      {slice.primary.link?.link_type !=="Any" ? 
        <PrismicNextLink field={slice.primary.link} linkResolver={linkResolver} className={(slice.primary.theme === true ? "border-offwhite": "border-warmcharcoal")+ " text-left my-8 border-[1px]  p-2 px-4 font-norma text-[12px] leading-auto md:text-[14px] font-medium w-[190px] inline-block h-[40px] relative"}>{slice.primary.link_text} <Arrow className={(slice.primary.theme === true ? "stroke-offwhite" : "stroke-warmcharcoal")+ " rotate-180 inline-block absolute right-3 top-3 "}/></PrismicNextLink>
      :<></>}
    </section>
  );
};

export default CtaPanel;
