'use client'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Content } from "@prismicio/client";
import { SliceComponentProps, PrismicRichText } from "@prismicio/react";

/**
 * Props for `ContactPanel`.
 */
export type ContactPanelProps = SliceComponentProps<Content.ContactPanelSlice>;

/**
 * Component for "ContactPanel" Slices.
 */
const ContactPanel = ({ slice }: ContactPanelProps): JSX.Element => {

  const { register, handleSubmit, watch, reset, formState: { errors }, setValue } = useForm();

  const [success, setSuccess] = useState(false)

  switch(slice.variation) {
    case 'default':
      return (
        <section
          data-slice-type={slice.slice_type}
          data-slice-variation={slice.variation}
          className="px-4 lg:px-[50px] relative md:-top-[100px] pb-24"
        >
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-4">
              <h2 className="font-sangbleu font-medium mb-12 text-[21px] leading-[32px]">{slice.primary.heading}</h2>
              <article className="font-norma text-[14px] leading-[21px] prose prose-p:pb-2 pb-12">
                <PrismicRichText field={slice.primary.description} />
              </article>
            </div>
            <div className="col-span-12 md:col-span-6">
              {success == false ? 
                <form onSubmit={handleSubmit(async (data: any) => {
                    // console.log(data)
                    const sendEmail = await fetch('/api/contact-email', {method: "post", body: JSON.stringify(data)})
                    const emailSent = await sendEmail.json()
                    setSuccess(emailSent?.success === "OK" ? true : false)
                  })}>
                  <div className="grid grid-cols-12 gap-5 font-norma text-[14px] text-warmcharcoal ">
                    <div className="col-span-12 md:col-span-6 relative">{errors.fname && <span className="text-warmgrey font-medium absolute top-[9px] right-4">Required</span>}
                        <input className="focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]  w-full text-[16px] md:text-[14px] p-3" placeholder="First name*" type="text" {...register("fname", {required: true})}/>
                    </div>
                    <div className="col-span-12 md:col-span-6 relative">{errors.lname && <span className="text-warmgrey font-medium absolute top-[9px] right-4">Required</span>}
                        <input className="focus:outline-none focus:ring-warmcharcoal focus:ring-[1px] w-full text-[16px] md:text-[14px] p-3" placeholder="Last name*" type="text" {...register("lname", {required: true})}/>
                    </div>
                    <div className="col-span-12 md:col-span-6 relative">{errors.email && <span className="text-warmgrey font-medium absolute top-[9px] right-4">Required</span>}
                      <input className="focus:outline-none focus:ring-warmcharcoal focus:ring-[1px] w-full text-[16px] md:text-[14px] p-3" placeholder="Email*" type="email" {...register("email", {required: true})}/>
                    </div>
                    <div className="col-span-12 md:col-span-6 relative">{errors.phone && <span className="text-warmgrey font-medium absolute top-[9px] right-4">Required</span>}
                      <input className="focus:outline-none focus:ring-warmcharcoal focus:ring-[1px] w-full text-[16px] md:text-[14px] p-3" placeholder="Phone number" type="text" {...register("phone", {required: true})}/>
                    </div>
                    <div className="col-span-12 bg-white relative">{errors.subject && <span className="text-warmgrey font-medium absolute top-[9px] right-4 z-10">Required</span>}
                      <select className="w-[calc(100%_-_10px)] h-[42px] px-3 bg-white" {...register("subject", {required: true})}>
                        <option>Subject*</option>
                        <option value="Feedback">Feedback</option>
                        <option value="Return a product">Return a product</option>
                        <option value="Product question">Product question</option>
                        <option value="Custom order enquiry">Custom order enquiry</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-span-12 relative">{errors.message && <span className="text-warmgrey font-medium absolute top-[9px] right-4">Required</span>}
                      <textarea className="focus:outline-none focus:ring-warmcharcoal focus:ring-[1px] w-full p-3 h-[200px]" placeholder="Message*" {...register("message", {required: true})}></textarea>
                    </div>
                    <div className="col-span-12">
                      <input className="focus:outline-none focus:ring-warmcharcoal focus:ring-[1px] w-full text-[16px] md:text-[14px] p-3 text-center bg-warmcharcoal text-offwhite" placeholder="Email*" type="submit" value="Send"/>
                    </div>
                  </div>
                </form>
                :
                <div className="h-full w-full flex items-center justify-center">
                  <h2 className="font-sangbleu font-medium mb-12 text-[21px] leading-[32px]">Thank you, someone will be in touch shortly.</h2>
                </div>}
            </div>
          </div>
        </section>
      );
    break;
    default: return <></>
  }
  
};

export default ContactPanel;
